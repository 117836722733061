import vvles1 from './Clients/vvles_1.png';
import vvles2 from './Clients/vvles_2.png';
import nanm from './Clients/nanm.png';
import mpk from './Clients/mpk.png';
import kk from './Clients/kk.png';
import ss from './Clients/ss.png';
import mend from './Clients/mend.jpeg';

export default function TheyTrustUs() {


    return (
        <>
        
        <div class='content'>
            <h1 class='center'>Нам доверяют клиенты</h1>
            <h5 class='center' style={
                {
                    marginBottom: '30px'
                }
            }>
                Нас выбирают бизнесы разного уровня — от стартапов до крупных компаний. 
                Мы создаем удобные и надежные решения, которые помогают расти и достигать целей. Присоединяйтесь к числу наших довольных клиентов!
            </h5>
            <div class='clients'>
                <div class='vvles'>
                    <img src={vvles1} title='АО «Вышневолоцкий леспромхоз»'/>
                    <img src={vvles2} title='АО «Вышневолоцкий леспромхоз»'/>
                </div>
                <img src={nanm} title='Национальная Профессиональная Ассоциация специалистов народной медицины и оздоровительных практик'/>
                <img src={mpk} title='МПК Тверецкий'/>
                <img src={kk} title='ООО «Конаковские колбасы»'   />
                <img src={ss} title='ТМ «Староселье»' />
                <img src={mend} title='ООО СЗ «ЖИЛСТРОЙ»'/>
            </div>
            <h5 class='center' style=
            {
                {
                    marginTop: '20px',
                    marginBottom: '30px'
                }

            }
            >и другие...</h5>


        </div>

        
        </>)



    }