import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import headerImg from './Assets/header2.png';
import logoImg from './Assets/2.png';
import StarBorderPurple500OutlinedIcon from '@mui/icons-material/StarBorderPurple500Outlined';
import { useState, useEffect, useLayoutEffect, useRef } from 'react'

import social_x from './Icons/x.svg';
import social_whatsapp from './Icons/whatsapp.svg';
import social_tg from './Icons/tg.svg';


export default function Header() {
    const bannerRef = useRef(null);
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
      //   function updateSize() {
      //     setSize([window.innerWidth, window.innerHeight]);
      //     console.log(window.innerWidth);
      //     var deg = -4.00;
      //     var bottom = 80.00; 
      //     if (window.innerWidth > 1550) { 
      //       deg = -0.00; 
      //       bottom = 0;
      //     }
      //     else if (window.innerWidth > 1500) {
      //       deg = -0.50;
      //       bottom = 10;
      //     }
      //     else if (window.innerWidth > 1450) {
      //       deg = -1.00;
      //       bottom = 20;
      //     }
      //     else if (window.innerWidth > 1400) {
      //       deg = -1.50;
      //       bottom = 30;
      //     }
      //     else if (window.innerWidth > 1350) {
      //       deg = -2.00;
      //       bottom = 40;
      //     }
      //     else if (window.innerWidth > 1300) { 
      //       deg = -2.50;
      //       bottom = 50;
      //     }
      //     else if (window.innerWidth > 1250) { 
      //       deg = -3.00;
      //       bottom = 60;
      //     }
      //     else if (window.innerWidth > 1200) { 
      //       deg = -3.50;
      //       bottom = 70;
      //     }
      //     else { 
      //       deg = -4.00;
      //       bottom = 80;
      //     }

      //     bannerRef.current.style.webkitTransform = 'rotate('+deg+'deg)'; 
      //     bannerRef.current.style.mozTransform    = 'rotate('+deg+'deg)'; 
      //     bannerRef.current.style.msTransform     = 'rotate('+deg+'deg)'; 
      //     bannerRef.current.style.oTransform      = 'rotate('+deg+'deg)'; 
      //     bannerRef.current.style.transform       = 'rotate('+deg+'deg)'; 
      //     bannerRef.current.style.bottom = bottom + 'px';
      // }
      //   window.addEventListener('resize', updateSize);
      //   updateSize();
      //   return () => window.removeEventListener('resize', updateSize);
      }, []);

      return (
        <>
            <div class='header-box' id='home'>
            <div class='header-wrap'>
            <div class='header-menu'  >
                    <img class='logo' src={logoImg}/>
                    <div class='menu' >
                        <Link>Главная</Link>
                        <Link>Наши работы</Link>
                        <Link>Технологии</Link>
                        <Link>Контакты</Link>
                    </div>
                    <div class='social'>
                        <a href='https://twitter.com' target='_blank'>
                          <img src={social_x}/>
                        </a> 
                       
                        <a href='https://web.telegram.org' target='_blank'>
                          <img src={social_tg}/>
                        </a> 
                    </div>
            </div>

            <div class='header'>
                <div class='header-left'>
                    <span class='header-text1'>
                        Разработка ПО под <span class="number">ваши задачи</span>
                    </span>
                    <span class='header-text2'>
                    Нужен надежный софт для бизнеса? Мы создаем удобные и эффективные решения — от мобильных приложений до веб-сервисов. Оставьте заявку, и мы поможем воплотить вашу идею в жизнь!
                    </span>
                    <Button size='large' 
                    onClick={
                        () => {
                            document.querySelector('#request').scrollIntoView({
                                behavior: 'smooth'
                              });
                        }
                    }
                     style={{
                        textTransform:'none',
                        borderRadius: 32,
                        fontSize: '18px',
                        fontWeight: '400',
                        display:'block'
                    }}
                    disableElevation
                     variant="contained">Заказать сайт или приложение
                     </Button>
                </div>
                <div class='header-right'>
                   <img class='header-img' src={headerImg} /> 
                </div>
            </div>
          
            </div>

            <div class='header-banner' ref={bannerRef}>
                    <span>C#</span><StarBorderPurple500OutlinedIcon/> 
                    <span>.NET</span><StarBorderPurple500OutlinedIcon/>   
                    <span>Blazor</span> <StarBorderPurple500OutlinedIcon/>   
                    <span>Kotlin</span>  <StarBorderPurple500OutlinedIcon/>   
                    <span>Flutter</span> <StarBorderPurple500OutlinedIcon/>   
                    <span>React</span>  <StarBorderPurple500OutlinedIcon/>   
                    <span>Microsoft Sql Server</span>  <StarBorderPurple500OutlinedIcon/>   
                    <span>MongoDb</span>     <StarBorderPurple500OutlinedIcon/>   
                    <span>Wpf</span> <StarBorderPurple500OutlinedIcon/>   
                    <span>WinForms</span>     <StarBorderPurple500OutlinedIcon/>   
                    <spam>Avalonia</spam><StarBorderPurple500OutlinedIcon/>   
             </div>      
            </div>
        </>)
}       
