import logo from './logo.svg';
import './App.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import TheyTrustUs from './TheyTrustUs';
import Works from './Works';
import Tech from './Tech';
import Request from './Request';

function App() {

  const theme = createTheme({
    palette: {
      primary: {
       
        main: '#0081CF',
       
      },
      secondary: {
     
        main: '#f44336',
      
      },
    },
    // palette: {
    //   primary: {
       
    //   },
    //   secondary: {
    //     main: '#f44336',
    //   },
    //   colors: {
       
    //   },
    // },
    root: {
    },
    typography: {
      "Montserrat": `"Roboto", "Helvetica", "Arial", sans-serif`,
     }

  });


  return (
<>
      <ThemeProvider theme={theme}>
         <Header /> 
         <TheyTrustUs />
         <Works />
         <Tech />
         <Request/>
         <Footer /> 
      </ThemeProvider>
    </>
    
  );
}

export default App;
