import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from "react"
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Request() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);

    async function submit(e) {
        e.preventDefault();
    }

    return (
        <>

            <div class='content' id='request'>
                <h1 class='center'>Оставить заявку</h1>
                <h5 class='center' style={
                    {
                        marginBottom: '30px'
                    }
                }>
                    Готовы начать сотрудничество? Заполните форму, и мы свяжемся с вами в ближайшее время, чтобы
                    обсудить ваш проект и предложить решение. Ждем вашей заявки!
                </h5>

    <div class='request-form'>

                <form onSubmit={(e) => submit(e)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column', rowGap: '10px'
                        }}>
                        <TextField
                            required
                            name="name"
                            label="Имя"
                            fullWidth
                            value={name}
                            onInput={e => setName(e.target.value)}
                            variant="outlined"
                        />

                        <TextField
                            required
                            name="email"
                            label="E-mail"
                            type="email"
                            value={email}
                            onInput={e => setEmail(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />

                        <TextField
                            label="Телефон"
                            name="tel"
                            type="tel"
                            fullWidth
                            variant="outlined"
                            value={tel}
                            onInput={e => setTel(e.target.value)}
                        >

                        </TextField>

                        <TextField
                            name="message"
                            minRows={2}
                            label="Сообщение"
                            multiline
                            required
                            type="text"
                            fullWidth
                            value={message}
                            onInput={e => setMessage(e.target.value)}
                            variant="outlined"
                        />

                        <Button type="submit" disableElevation 
                        sx={{borderRadius: '22px',
                            textTransform:'none'
                        }}
                                variant="contained"
                                style={{margin: 'auto'}}
                        >
                            Отправить
                        </Button>
                    </div>
                </form>


            </div>
            </div>

        </>)

}