import Link from '@mui/material/Link';

export default function Footer() {

    return (
        <>

        <div class='footer' style={{
            
           
        }}>

            <span class='footer-inf'>Нужен надежный софт для бизнеса? Мы создаем удобные и эффективные решения — от мобильных приложений до веб-сервисов. Оставьте заявку, и мы поможем воплотить вашу идею в жизнь!</span>

            <div class='footer-nav'>
                <Link href='#home'>Главная</Link>
                &#8226;
                <Link href='#works'>Наши проекты</Link>
                &#8226;
                <Link href='#tech'>Технологии</Link>
                &#8226;
                <Link href='#request'>Контакты</Link>
            </div>

            <span>Сделано с любовью <span class='hearts'>&hearts;</span> и <Link target='_blank' href='https://react.dev/'>React</Link>. &nbsp;
            <b>NeoCode LLC</b> © 2010 - 2024.</span>

        </div>


        </>)
}       


