import img1 from './Works/1.png';
import img2 from './Works/2.png';
import img21 from './Works/2-1.png';
import img22 from './Works/2-2.png';
import img3 from './Works/3.png';
import img4 from './Works/4.png';
import img6 from './Works/6.png';
import img7 from './Works/7.png';
import img8 from './Works/8.png';
import img81 from './Works/8-1.png';
import img9 from './Works/9.png';

export const data = [ 
        {
            descr: 'Пример сайта компании занимающейся производством изделии из бруса.' ,
            img: img1,
            href: 'https://vvlesprom.com',
            name:'vvlesprom.com',
            tech: [ 'ASP.NET', 'WebForms' ],
            year: '2016 г.',
            id: 1
        },
        {
            descr: 'Личный кабинет для производственной компании. Управления заказами клиентов. Вдохновлены Trello. Чаты, ' +
                'файлы, все как в Trello. Карточки. Права доступа. Уведомления...',
            img: img2,
            href: 'https://lk.vvlesprom.com',
            name:'lk.vvlesprom.com',
            tech: [ 'ASP.NET', 'SignalR' ],
            year: '2017 г.',
            id: 2,
            images: [ img2,img21,img22 ]
        },
        // {
        //     descr: 'Личный кабинет компании, для управления заказами клиентов. Чаты, файлы, все как в Trello.',
        //     img: img21,
        //     name: 'lk.vvlesprom.com',
        //     href: 'https://lk.vvlesprom.com',
        //     tech: [ 'ASP.NET', 'SignalR'  ],
        //     year: '2017 г.',
        //     id: 3
        // },
        // {
        //     descr: 'Личный кабинет компании, для управления заказами клиентов. Карточки. Права доступа. Уведомления пользователям на сайт и эл.почту...',
        //     img: img22,
        //     name: 'lk.vvlesprom.com',
        //     href: 'https://lk.vvlesprom.com',
        //     tech: [ 'ASP.NET', 'SignalR' ],
        //     year: '2017 г.',
        //     id: 4
        // },
        {
            descr: 'Сайт для ООО "Конаковские колбасы". Продукция представлена в магазинах и супермаркетах Тверской и Московской областей.',
            img: img3,
            name: 'konakovskie.ru',
            href: 'https://konakovskie.ru/',
            tech: [ 'ASP.NET Core', 'Blazor' ],
            year: '2022 г.',
            id: 5
        },
        {
            descr: 'Сайт для ООО "МПК Тверецкий". ',
            img: img4,
            name: 'мпк-тверецкий.рф',
            href: 'https://xn----dtbjapgbem4apv0d.xn--p1ai/',
            tech: [ 'ASP.NET' ],
            year: '2020 г.' ,
            id: 6
        },
        {
            descr: 'Сайт для "Национальная Профессиональная Ассоциация специалистов народной медицины и оздоровительных практик". Настраиваемый редактор форм для мероприятии, прием платежей...',
            img: img6,
            name: 'nanmprof.org',
            href: 'https://nanmprof.org/',
            tech: [ 'ASP.NET Core', 'Blazor' ],
            year: '2021 г.'            ,
            id: 7
        },
        {
            descr: 'Сайт для "Организационно-правовой центр Владимира Егорова". Настраиваемый контент, прием платежей...',
            img: img7,
            name: 'владимирегоров.рф',
            href: 'https://xn--80adbdeguaxnzawd.xn--p1ai/',
            tech: [ 'ASP.NET Core', 'Blazor' ],
            year: '2021 г.',
            id: 8
        },
        {
            descr: 'Сайт "Жилой Комплекс Менделеев, 69". Выбор квартиры и машиноместа на поэтажном плане. Резервирование, продажи...',
            img: img8,
            name: 'менделеев39.рф',
            href: 'https://xn--39-dlchebaa6cgm.xn--p1ai/',
            tech: [ 'React' ],
            year: '2024 г.',
            id: 9
        },
        {
            descr: 'Сайт "Жилой Комплекс Менделеев, 69". Детальный план квартиры.',
            img: img81,
            name: 'менделеев39.рф',
            href: 'https://xn--39-dlchebaa6cgm.xn--p1ai/',
            tech: [ 'React' ],
            year: '2024 г.',
            id: 10
        },
        {
            descr: 'Приложение для интерактивного написания и тестирования LINQ запросов к реляционным базам данных. Содержит функцию автоподстановки IntelliSense. Размещена в англоязычном сегменте Microsoft Store. Аналог LinqPad.',
            img: img9,
            name: 'LinqCode',
            href: 'https://apps.microsoft.com/detail/9p26rgmfp42b?SilentAuth=1&wa=wsignin1.0&hl=en-us&gl=US',
            tech: [ 'Wpf' , 'C#' ],
            year: '2021 г.'            ,
            id: 11
        }];
   
       
