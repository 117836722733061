export const data = [ 
        {
            name:'ASP.NET',
            color1: '#B975DA',
            color2: '222222',
            id: 1
        },
        {
            name:'React',
            color1: '#B5E71B',
            color2: '#2D4000',
            id: 2
        },
        {
            name:'JavaScript',
            color1: '#EFD81D',
            color2: '#313131',
            id: 6
        },
        {
            name:'Kotlin',
            color1: '#E70189',
            color2: '#FFFFFF',
            id: 3
        },     
        
        {
            name:'Blazor',
            color1: '#AC99EA',
            color2: '#242424',
            id: 5
        },
        {
            name:'C#',
            color1: '#603ED1',
            color2: '#FFFFFF',
            id: 4
        },
        ];
   
       
