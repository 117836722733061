import tech  from './Assets/_b0fcf9f3-7fd6-49c1-94b0-cb1b9b43d29d.jpg'
import { data } from './TechData.js'
import { useState, useEffect, useLayoutEffect, useRef } from 'react'

export default function Tech() {
    const s = useRef([]);
    const imgRef = useRef(null);
    var isTechVisible = false;

    const display = () => {
        if (!isTechVisible)
        {
            isTechVisible = true;
            //alert('dfsd');
            anim();
        }
        //console.log(target.replace("id_", ""));
   }


    function isVis() {
        const target = document.querySelector('#tech');
        const top = target.getBoundingClientRect().top + 300 || 0;
        return top >= 0 && top <= window.innerHeight;
    }

    const myScrollHandler = () => {
        if (isVis()) display();
    }

    useEffect(() => {
        document.addEventListener("scroll", myScrollHandler);
        isTechVisible = false;
        if (isVis()) anim();
        return () => document.removeEventListener("scroll", myScrollHandler);
    }, []);

    function anim() {
      
        s.current = s.current.slice(0, data.length);

          
          const newspaperTiming = {
            duration: 400,
            iterations: 1,
          };

          var r = imgRef.current.offsetWidth > imgRef.current.offsetHeight ? imgRef.current.offsetWidth : imgRef.current.offsetHeight;
          r = r / 2 - 50;
          
          //alert(imgRef.current.offsetWidth);

          var degOffset = 360 / (s.current.length + 2);
          var degStart = 0;

          s.current.map((it, i) => 
            {
                //alert(Math.random() * 30);
             const newspaperSpinning = [
                { transform: "rotate(0) scale(0)" },
                { transform: "rotate(" + (30 + Math.random() * -90) + "deg)" },
              ];


              it.style.display = 'block';
              degStart = degStart + degOffset;
                var x =  r * Math.sin(degStart) + r + 50;
                var y =  r * Math.cos(degStart) + r + 50;
                it.style.top =  (y -  Math.round(it.offsetHeight/2))  + 'px';
                it.style.left = (x - Math.round(it.offsetWidth/2)) + 'px';
                

                it.animate(newspaperSpinning, newspaperTiming)
            }
            
        )
          

    }

    function calcDeg() {
        return 'rotate(' + (30 + Math.random() * -60)  + 'deg)';
    }
    function calcTop(i) {
        return (20 + i * 100) + 'px';
    }
    function calcLeft(i) {
        return (-20 + Math.random() * 500) + 'px';
    }

    return (
        <>
        
        <div class='content' id='tech'>
            <h1 class='center'>Технологии, которые мы применяем</h1>
            <h5 class='center' style={
                {
                    marginBottom: '30px'
                }
            }>
              
                Мы используем современные технологии для создания качественного ПО. 
                Наша команда работает с C#, JavaScript, React, Blazor, .NET Core, MAUI и Kotlin, 
                чтобы предложить лучшие решения для вашего бизнеса.
            </h5>

            <div class='tech' >
                <img src={tech} ref={imgRef} id='tech'/>
                {
                    data.map((it,i) => (
                      <div class='tech-it' key={it.id} ref={
                        el => s.current[i] = el
                        } style={{
                            backgroundColor: it.color1,
                            display:'none',
                            color: it.color2,
                             transform: calcDeg(),
                             //left: calcLeft(i),
                             //top: calcTop(i)
                      }}>{it.name}</div>
                   ))
                }
            </div>
            

         </div>
       

        </>

) } 