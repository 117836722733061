import React from 'react'
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Button from '@mui/material/Button';
import { useState, useEffect, useRef } from 'react'
import { data } from "./ClientData.js";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

import Slider from './Slider.jsx';

export default function Works() {
    const [idx, setIdx] = useState(0);
    const el1Ref = useRef(null);
    const el2Ref = useRef(null);
    
    //onmouseover = (event) => {};

    useEffect(() => {
        // el1Ref.current.addEventListener("mouseover", (event) => {
        //     el2Ref.current.style = 'display:block';
        //     //alert('321');
        // });
        // el1Ref.current.addEventListener("mouseleave", (event) => {
        //     el2Ref.current.style = 'display:none';
        //     //alert('321');
        // });

       // return () => el1Ref.current.removeEventListener('mouseover', updateSize);
    }, [])

    function scroll(direction) {
        setIdx(idx + direction);        
    }


    return (
        <>

             <div class='case-sect' id='works'>
             <h1 class='center'>Наши проекты</h1>
        <h5 class='center' style={
                {
                    marginBottom: '30px'
                }
            }>
              Мы воплотили в жизнь разные идеи и задачи для наших клиентов. Ознакомьтесь с нашими проектами и узнайте, как мы можем помочь вашему бизнесу!
            </h5>

            <div class='case-all'>

            <div class='case-box'  >
                <Slider images={data[1].images}></Slider>

                <div class='case-inf'>
                    <div class='case-head'>
                    <h4>{data[1].descr}</h4>
                    <div class='case-live'>
                      <Link title='Перейти к сайту' href={data[1].href} target='blank'>
                          <OpenInNewIcon style={{fontSize:'36px'}}/>
                        </Link>
                    </div>    
                    </div>

                    <div class='case-tag'>
                    {data[1].tech.map((it) => (
                       <Button  disableElevation size='small'
                       style={{
                        textTransform:'none',
                        borderRadius: 32,
                    }}
                       variant="contained">{it}</Button>  
                    ))
                    }

                <Button  disableElevation size='small'
                       style={{
                        textTransform:'none',
                        borderRadius: 32,
                    }}
                       variant="outlined">{data[1].year}</Button>  
                </div>
              
                </div>
          
                </div>

</div> 
</div> 

        </>)


    }
        


  {/*
            <div class='case-box'>

            <div class='case-im'>
                    <img src={data[0].img}/>
                </div>    
                <div class='case-inf'>
                    <h3>{data[0].descr}</h3>

                    <div class='case-tag'>
                    {data[idx].tech.map((it) => (
                       <Button  disableElevation size='small'
                       style={{
                        textTransform:'none',
                        borderRadius: 32,
                    }}
                       variant="contained">{it}</Button>  
                    ))
                    }

                <Button  disableElevation size='small'
                       style={{
                        textTransform:'none',
                        borderRadius: 32,
                    }}
                       variant="outlined">{data[idx].year}</Button>  
                </div>
                <div class='case-live'>
                    <Link href={data[0].href} target='blank'>
                        <OpenInNewIcon style={{fontSize:'44px'}}/>
                    </Link>
                </div>    
                </div>

             
            </div>


            
            <div class='case-box'  >
                <div class='case-im' ref={el1Ref}>
                    <img src={data[1].img}/>
                    <div class='case-hide'  ref={el2Ref}>dsffdsdffsdfdsfds</div>
                </div>   
                <div class='case-inf'>
                    <div class='case-head'>
                    <h3>{data[1].descr}</h3>
                    <div class='case-live'>
                      <Link href={data[1].href} target='blank'>
                          <OpenInNewIcon style={{fontSize:'40px'}}/>
                        </Link>
                    </div>    
                    </div>

                    <div class='case-tag'>
                    {data[1].tech.map((it) => (
                       <Button  disableElevation size='small'
                       style={{
                        textTransform:'none',
                        borderRadius: 32,
                    }}
                       variant="contained">{it}</Button>  
                    ))
                    }

                <Button  disableElevation size='small'
                       style={{
                        textTransform:'none',
                        borderRadius: 32,
                    }}
                       variant="outlined">{data[1].year}</Button>  
                </div>
              
                </div>

              
            </div>



                <div style={{
                    position:'relative'
                 }}>
                <div class='case-img'>
                    <img src={data[idx].img}/>
                </div>
                    {
                        idx !== 0 &&
                    <div class='case-nav-left'>
                        <Button style={{
                            width:'20px',
                            height:'60px'
                        }} onClick={() => scroll(-1) }>
                            <ArrowBackIosRoundedIcon style={{
                                fontSize: '40px'
                            }}/>
                        </Button>
                    </div>
                    }
                    {
                        idx < (data.length-1) &&
                    <div class='case-nav-right'>
                    <Button  style={{
                            width:'20px',
                            height:'60px'
                        }} onClick={ () => scroll(1) }>
                            <ArrowForwardIosRoundedIcon style={{
                                fontSize: '40px'
                            }}/>
                        </Button>
                    </div>
                    }
                </div>
                <div class='case-details'>
                <div class='case-info'>
                        <span>{data[idx].descr}</span>
                        <span>&nbsp;&nbsp;</span>
                        <Link href={data[idx].href} target='_blank'>
                            {data[idx].name}
                        </Link>
                
                </div>
                <div class='case-tag'>
                    {data[idx].tech.map((it) => (
                       <Button  disableElevation size='small'
                       style={{
                        textTransform:'none',
                        borderRadius: 32,
                    }}
                       variant="contained">{it}</Button>  
                    ))
                    }

                <Button  disableElevation size='small'
                       style={{
                        textTransform:'none',
                        borderRadius: 32,
                    }}
                       variant="outlined">{data[idx].year}</Button>  
                </div>
                </div>
                <div class='case-pag'>
                {
                    data.map((it) => (
                            idx === data.indexOf(it) ?
                            <div key={it.id} class='slide-point selected' onClick={()=>setIdx(data.indexOf(it))}/> :
                            <div key={it.id} class='slide-point' onClick={()=>setIdx(data.indexOf(it))}/>
                    )
                )
                }
                </div>

                    

             </div>    */}
