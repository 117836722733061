import React from 'react'
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Button from '@mui/material/Button';
import { useState, useEffect, useRef } from 'react'

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

//var isAuto = true;
var counter = 0;
var isOverNav = false;
var isAuto = true;

export default function Slider(props) {
    const [idx, setIdx] = useState(0);
  
    const el2Ref = useRef(null);
    const elRefs = useRef([]);
    const leftNavRef = useRef(null);
    const rightNavRef = useRef(null);
    const sRef = useRef(null);


    
   // var isAuto ;

    // useEffect(() => {
    // }, [props.it])

    function scroll(direction) {
        //setIsAuto(false);
     
        counter = direction;
        setIdx(direction);      
        scroll2(direction);
    }

    const scrollIntoViewWithOffset = (selector, offset) => {
        // window.scrollTo({
        //   behavior: 'smooth',
        //   top:
        //     document.querySelector(selector).getBoundingClientRect().top -
        //     document.body.getBoundingClientRect().top -
        //     offset,
        // })

      
        

        el2Ref.current.scrollTo({
            top: 0,
            left: counter * selector.offsetWidth + 1,
            behavior: 'smooth'
          });

        //scrollIntoViewWithOffset('#caseslideimage' + counter, 100);
        //el2Ref.current.querySelector(selector).scrollIntoView({
           
            // block: "center",
          //   behavior: 'smooth'
           //});        
      }

    function scroll2(i) {
        //alert('#caseslideimage' + idx);
        scrollIntoViewWithOffset(elRefs.current[i], 100);
        // document.querySelector('#caseslideimage' + counter).scrollIntoView({ 
        //     block: 'start',
        //     behavior: 'smooth'
        //   });
    }

    function changeSlide() {
            // if (isAuto === false) return;
            
            // console.log(isAuto);

            //var _idx = idx + 1;
            //alert(idx + 1 > props.images.length);
            //alert((idx + 1) > (props.images.length));
            //if ((idx + 1) > (props.images.length)) setIdx(0);

        //console.log(idx+1 > (props.images.length-1));

    //    console.log(isAuto);
        if (isAuto) {
            counter++;

            if (counter > (props.images.length - 1)) counter = 0;

            scroll2(counter);
          //  console.log(counter);
            setIdx(counter);
        }

            //alert(counter);

    }


    useEffect(() => {


        // if (idx > (props.images.length-1)) {
        //     setIdx(0);
        //
        // }

        //var s = idx + 1;

        // alert(s);

        //if (s > (props.images.length-1)) s = 0;

        //console.log(s);

        //scroll2();

    },[ idx ])
  
    useEffect(() => {
        const interval = setInterval(changeSlide, 4000);
        elRefs.current = elRefs.current.slice(0, props.images.length);

      

        el2Ref.current.addEventListener("mouseover", (event) => {
            //setIsAuto(false);
         //   if (leftNavRef.current && counter != 0) leftNavRef.current.style = 'visibility: visible';
           // if (rightNavRef.current) rightNavRef.current.style = 'visibility: visible';
        });
        el2Ref.current.addEventListener("mouseleave", (event) => {
            //alert(event.offsetX);
           // if (!(event.offsetX >= 0 && event.offsetX <= 40))
             //   setIsAuto(true);
          //  else
            //    setIsAuto(false);

            //isAuto = true;
            if (!isOverNav)
            {
             //   if (leftNavRef.current) leftNavRef.current.style = 'visibility: hidden';
               // if (rightNavRef.current) rightNavRef.current.style = 'visibility: hidden';
            }
        });

        return () => { 
            clearInterval(interval);
            // el2Ref.current.removeEventListener("mouseleave");
            // el2Ref.current.removeEventListener("mouseover");
        }
      }, [props]);

      const hoverHandler = () => {
        console.log("onMouseEnter")
        isAuto = false;
        leftNavRef.current.style = 'visibility:visibile';
        rightNavRef.current.style = 'visibility:visibile';

      }
      const outHandler = () => {
        isAuto = true;
        console.log("onMouseLeave")
        leftNavRef.current.style = 'visibility:hidden';
        rightNavRef.current.style = 'visibility:hidden';
      }


    return(
        <>
        
            <div class='case-im'  onMouseEnter={hoverHandler}
        onMouseLeave={outHandler} >


                <div class='case-im2' ref={el2Ref}   >
                    {props.images.map((it,i) => (
                        <img src={it} 
                        ref={
                            el => elRefs.current[i] = el
                            } 
                        key={it}/>
                    ))
                    }
                </div>


            <div class='case-pag'>
                {
                    props.images.map((it,i) => (
                            idx === i ?
                            <div key={it} class='slide-point selected' onClick={()=>scroll(i)}/> :
                            <div key={it} class='slide-point' onClick={()=>scroll(i)}/>
                    )
                )
                }
                </div>

                {
                    <div class='case-nav-left' 
                    style={{ 
                        // visibility: idx !== 0 && !isAuto  ? 'visible' : 'hidden' 
                        
                    }} 
                    ref={leftNavRef}>
                        <Button style={{
                        }} onClick={() => scroll(idx - 1) }>
                            <ArrowBackIosRoundedIcon style={{
                                fontSize: '40px'
                            }}/>
                        </Button>
                    </div>
                    }

{

                    <div class='case-nav-right' ref={rightNavRef}
                    style={{ 
                        // visibility:  idx < (props.images.length-1) && !isAuto ? 'visible' : 'hidden' 
                        
                    }} 
                    >
                    <Button  style={{
                        }} onClick={ () => scroll(idx + 1) }>
                            <ArrowForwardIosRoundedIcon style={{
                                fontSize: '40px'
                            }}/>
                        </Button>
                    </div>
                    }


            </div>    
        </>
    )
}